import {
    IconCheck,
    IconChevronRight,
    IconCirclePlus,
    IconPencil,
    IconSearch,
    IconTrash,
    IconX
} from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
//
import { IModal } from "@/components/Modal/modal";
import { createPredefinedText, deletePredefinedText, getPredefinedTexts, updatePredefinedText } from "@/features/Workspace/Gate/PredefinedTextsNoIns/predefinedTexts.actions";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { PredefinedText, PredefinedTextsResponse } from "@/models";
//
import { Button, Modal, Table, TextField } from "@/components";
import "../../Oftalmology/TextConfig/TextConfig.scss";
import "./TextConfig.scss";

interface ITextConfigModal extends IModal {
    onSelectItem: (current: string, item: string) => void;
    current: string;
    segment?: string;
    sheet?: string;
}

const detailSegments: Record<string, string> = {
    reasonForConsulting: "reason_attention",
    currentDisease: "current_disease",
    analysisAndPlan: "analysis_plan"
};

interface TextPayload {
    id?: number;
    dtlSegmentPrefix?: string;
    currentReason: string;
    selectedText: string;
    isSelectedTextDisabled: boolean;
    creatable?: boolean;
}

export default function TextConfigModal({ onClose, onSelectItem, current, segment, sheet, ...rest }: ITextConfigModal) {
    const dispatch = useAppDispatch();
    const accountId = useAppSelector(state => state.workspace.id);
    const userId = useAppSelector(state => state.auth.user_data.id);
    const selectedTextRef = useRef<HTMLTextAreaElement>(null);
    const sheetSelector = useAppSelector(state => state.patientAttention.patientStatus.sheet);
    const [currentSheet, setCurrentSheet] = useState("");
    const [predefinedTextsResult, setPredefinedTextsResult] = useState<PredefinedTextsResponse>();
    const [selectedText, setSelectedText] = useState<PredefinedText | null>(null);
    const [searchValue, setSearchValue] = useDebounce("", 1000);

    const [textPayload, setTextPayload] = useState<TextPayload>({
        currentReason: "",
        selectedText: "",
        isSelectedTextDisabled: true
    });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [charCount, setCharCount] = useState(2000);

    useEffect(() => {
        if (sheet) {
            setCurrentSheet(sheet);
        } else {
            setCurrentSheet(sheetSelector);
        }
    }, [sheet, sheetSelector]);

    useEffect(() => {
        if (!textPayload.isSelectedTextDisabled)
            selectedTextRef.current?.focus();
    }, [textPayload.isSelectedTextDisabled]);

    useEffect(() => {
        async function fetchData() {
            if (rest.isOpen) {
                const data = await dispatch(getPredefinedTexts({
                    eaccount: accountId,
                    type: "non-institutional",
                    search: searchValue,
                    users: [userId as number],
                    component: currentSheet,
                    dtlSegmentPrefix: segment ? segment : detailSegments[current]
                }));
                setPredefinedTextsResult(data as PredefinedTextsResponse);
            }
        }
        fetchData();
    }, [dispatch, searchValue, accountId, userId, currentSheet, current, rest.isOpen, segment]);

    const handleCloseModal = () => {
        setSelectedText(null);
        setTextPayload({
            id: undefined,
            dtlSegmentPrefix: undefined,
            currentReason: "",
            isSelectedTextDisabled: true,
            selectedText: ""
        });
        setSearchValue("");
        onClose();
    };

    const handleSearch = (value: string): void => setSearchValue(value);

    const handleSelectText = (text: PredefinedText) => {
        setSelectedText(text);
        setTextPayload({
            ...textPayload,
            creatable: false,
            id: text.id,
            dtlSegmentPrefix: segment ? segment : detailSegments[current],
            selectedText: text.description,
            isSelectedTextDisabled: true,
            currentReason: text.description
        });
        setCharCount(2000 - text.description.length);
    };

    const handleUpdateJustification = (value: string) => {
        if (value.length <= 2000) {
            setTextPayload({
                ...textPayload,
                currentReason: value
            });
            setCharCount(2000 - value.length);
        }
    };

    const isSuccessRequest = async () => {
        const data = await dispatch(getPredefinedTexts({
            eaccount: accountId,
            type: "non-institutional",
            search: searchValue,
            users: [userId as number],
            active: 1,
            component: currentSheet,
            dtlSegmentPrefix: segment ? segment : detailSegments[current]
        }));
        setPredefinedTextsResult(data as PredefinedTextsResponse);
        setTextPayload({
            id: undefined,
            currentReason: "",
            selectedText: "",
            isSelectedTextDisabled: true
        });
        setSelectedText(null);
    };

    const handleCreatePredefinedText = async () => {
        try {
            setIsLoading(true);
            const isSuccess = await dispatch(createPredefinedText({
                type: "non-institutional",
                createdBy: userId as number,
                description: textPayload.currentReason,
                dtlSegmentPrefix: segment ? segment : detailSegments[current],
                eaccount: accountId,
                component: currentSheet,
                users: [userId as number]
            }));
            if (isSuccess) {
                await isSuccessRequest();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdatePredefinedtext = async () => {
        try {
            setIsLoading(true);
            const isSuccess = await dispatch(updatePredefinedText({
                id: textPayload.id,
                updatedBy: userId as number,
                type: "non-institutional",
                description: textPayload.currentReason,
                dtlSegmentPrefix: segment ? segment : detailSegments[current],
                eaccount: accountId,
                component: currentSheet,
                active: true
            }));
            if (isSuccess) {
                await isSuccessRequest();
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeletePredefinedText = async () => {
        const isSuccess = await dispatch(deletePredefinedText({
            active: false,
            eaccount: accountId,
            id: textPayload.id,
            updatedBy: userId as number,
            component: currentSheet,
        }));
        if (isSuccess) {
            await isSuccessRequest();
        }
    };

    const handleAddNewText = async () => {
        setCharCount(2000);
        setTextPayload({
            dtlSegmentPrefix: segment ? segment : detailSegments[current],
            selectedText: "",
            currentReason: "",
            isSelectedTextDisabled: false,
            creatable: true
        });
    };

    const renderSelectedText = (item: PredefinedText) => {
        if (textPayload.creatable) {
            return null;
        }

        return (
            <div className="flex-grow-1" style={{ marginTop: 40 }}>
                <div className="row">
                    <div className="col">
                        <div className="text-muted fw-bold mt-1">Código</div>
                        <div className="text-muted fw-bold mt-1">Módulo</div>
                        <div className="text-muted fw-bold mt-1">Componente</div>
                        <div className="text-muted fw-bold mt-1">Segmento</div>
                        <div className="text-muted fw-bold mt-1">Detalle del segmento</div>
                    </div>
                    <div className="col">
                        <div className="mt-1">{item.id}</div>
                        <div className="mt-1">{item.moduleName}</div>
                        <div className="mt-1">{item.componentName}</div>
                        <div className="mt-1">{item.segName}</div>
                        <div className="mt-1">{item.dtlSegmentName}</div>
                    </div>
                </div>
            </div>
        );
    };

    const renderTextArea = () => {
        return (
            <div className="textarea__with-header mt-3">
                <div className="textarea-header">
                    <span>Descripción</span>
                </div>
                <textarea
                    title="Descripción"
                    ref={selectedTextRef}
                    value={textPayload.currentReason}
                    rows={6}
                    readOnly={textPayload.isSelectedTextDisabled}
                    onChange={({ target }) => handleUpdateJustification(target.value)}
                />
                <div className="px-2">{charCount} caracteres restantes</div>
            </div>
        );
    };

    const renderRow = (item: PredefinedText) => {
        return (
            <tr key={item.id}>
                <td
                    style={{ minWidth: "60px" }}
                    onClick={() => handleSelectText(item)}
                >
                    {item.id}
                </td>
                <td
                    style={{ width: "100%" }}
                    onClick={() => handleSelectText(item)}
                >
                    {item.description}
                </td>
                <td>
                    <IconChevronRight
                        size={15}
                        className="text-secondary"
                        onClick={() => {
                            onSelectItem(segment ? segment : current, item.description);
                            setSearchValue("");
                            setTextPayload({
                                id: undefined,
                                dtlSegmentPrefix: undefined,
                                currentReason: "",
                                isSelectedTextDisabled: true,
                                selectedText: ""
                            });
                        }}
                    />
                </td>
            </tr>
        );
    };

    const renderTable = () => {
        return (
            <Table className="textconfig-modal__table-wrapper" isCompact>
                <thead>
                    <tr>
                        <th style={{ minWidth: "60px" }}>Código</th>
                        <th style={{ width: "100%" }}>Descripción</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody style={{ maxHeight: 250 }}>
                    {predefinedTextsResult?.results?.map(item => renderRow(item))}
                </tbody>
            </Table>
        );
    };

    const render = () => {
        return (
            <Modal onClose={handleCloseModal} {...rest}>
                <h2 className="fw-bold text-secondary">
                    Textos predefinidos no institucionales
                </h2>
                <div className="row gx-5 w-100 mt-3">
                    <div className="col">
                        <TextField
                            className="w-100 mb-3"
                            variant="filled"
                            endIcon={<IconSearch />}
                            placeholder="Escribe código o descripción..."
                            onChange={({ target }) => handleSearch(target.value)}
                        />
                        {renderTable()}
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="text-muted fw-bold">Total</span>
                            <span className="text-muted">{predefinedTextsResult?.results?.length}</span>
                            <div></div>
                        </div>
                    </div>
                    <div className="col d-flex flex-column">
                        <Button
                            variant="primary"
                            className="align-self-end"
                            endIcon={<IconCirclePlus />}
                            onClick={handleAddNewText}
                            isLoading={isLoading}
                        >
                            Agregar
                        </Button>
                        {selectedText ? (
                            <>
                                {renderSelectedText(selectedText)}
                                {renderTextArea()}
                            </>
                        ) : (
                            !textPayload.isSelectedTextDisabled ? (
                                <div className="textarea__with-header mt-3">
                                    <div className="textarea-header">
                                        <span>Descripción</span>
                                    </div>
                                    <textarea
                                        title="Descripción"
                                        ref={selectedTextRef}
                                        value={textPayload.currentReason}
                                        rows={6}
                                        readOnly={textPayload.isSelectedTextDisabled}
                                        onChange={({ target }) => handleUpdateJustification(target.value)}
                                    />
                                    <div className="px-2">{charCount} caracteres restantes</div>
                                </div>
                            ) : (
                                <h3 className="text-muted text-center mt-4">
                                    Seleccione un texto predefinido
                                </h3>
                            )
                        )}
                        <div className="d-flex justify-content-end align-items-center">
                            {textPayload.isSelectedTextDisabled
                                ? textPayload.selectedText ? (
                                    <>
                                        <IconPencil
                                            size={20}
                                            className="text-muted pointer me-2"
                                            onClick={() => setTextPayload({
                                                ...textPayload,
                                                isSelectedTextDisabled: false,
                                                creatable: false
                                            })}
                                        />
                                        <IconTrash
                                            size={20}
                                            className="text-muted pointer"
                                            onClick={handleDeletePredefinedText}
                                        />
                                    </>
                                ) : null
                                : !isLoading ? (
                                    <>
                                        <IconCheck
                                            size={20}
                                            className="text-success pointer me-2"
                                            onClick={textPayload.creatable ? handleCreatePredefinedText : handleUpdatePredefinedtext}
                                        />
                                        <IconX
                                            size={20}
                                            className="text-danger pointer"
                                            onClick={() => setTextPayload({
                                                ...textPayload,
                                                isSelectedTextDisabled: true,
                                                currentReason: textPayload.selectedText,
                                            })}
                                        />
                                    </>
                                ) : (
                                    <div className="small-loader"></div>
                                )}
                        </div>
                    </div>
                </div>
            </Modal >
        );
    };

    return render();
}